$purple-color: #1d002d;
$purple-light-color: #8b05d3;
$green-color: #1bad0b;

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

h1 {
    font-weight: bold;
    color: white;
}

body {
    background-image: radial-gradient(circle at 50% 50%, #522a4e 0, #411b44 25%, #2e0838 50%, #1d002d 75%, #100026 100%);
    font-family: Roboto, Courier, monospace;
    font-style: normal;
}

input {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
}

.mt-1{
    margin-top: 5px;
}
.mt-2{
    margin-top: 10px;
}
.mt-3{
    margin-top: 15px;
}
.mt-4{
    margin-top: 20px;
}

.mb-1{
    margin-bottom: 5px;
}
.mb-2{
    margin-bottom: 10px;
}
.mb-3{
    margin-bottom: 15px;
}
.mb-4{
    margin-bottom: 20px;
}

.ml-1{
    margin-left: 5px;
}
.ml-2{
    margin-left: 10px;
}
.ml-3{
    margin-left: 15px;
}
.ml-4{
    margin-left: 20px;
}

.mr-1{
    margin-right: 5px;
}
.mr-2{
    margin-right: 10px;
}
.mr-3{
    margin-right: 15px;
}
.mr-4{
    margin-right: 20px;
}

.pt-1{
    padding-top: 5px;
}
.pt-2{
    padding-top: 10px;
}
.pt-3{
    padding-top: 15px;
}
.pt-4{
    padding-top: 20px;
}

.pb-1{
    padding-bottom: 5px;
}
.pb-2{
    padding-bottom: 10px;
}
.pb-3{
    padding-bottom: 15px;
}
.pb-4{
    padding-bottom: 20px;
}

.pr-1{
    padding-right: 5px;
}
.pr-2{
    padding-right: 10px;
}
.pr-3{
    padding-right: 15px;
}
.pr-4{
    padding-right: 20px;
}

.pl-1{
    padding-left: 5px;
}
.pl-2{
    padding-left: 10px;
}
.pl-3{
    padding-left: 15px;
}
.pl-4{
    padding-left: 20px;
}

.w-100{
    width: 100%;
}

.w-90{
    width: 90%;
}

.w-80{
    width: 80%;
}

.w-70{
    width: 70%;
}

.w-60{
    width: 60%;
}

.w-50{
    width: 50%;
}

.w-40{
    width: 40%;
}

.w-30{
    width: 30%;
}

.w-20{
    width: 20%;
}

.w-10{
    width: 10%;
}