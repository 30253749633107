.btn-container{
    display: flex;
    justify-content: space-around;
}

.btn {
    padding: 10px;
    border-radius: 2px;
    transition: all 0.4s ease;
    cursor: pointer;
}

.btn-purple {
    background-color: transparentize($color: $purple-color, $amount: 0.8);
    border: 2px solid lighten($color: $purple-color, $amount: 15);
    color: white;

    &:hover {
        color: lighten($color: $green-color, $amount: 10);
        background-color: transparentize($color: $purple-color, $amount: 0.2);
        border: 2px solid transparentize($color: #000000, $amount: 1);
    }

    &:focus {
        color: white;
        border: 2px solid $purple-color;
    }
}

.btn-home{
    padding: 30px 0px 30px 0px;
    box-shadow: 2px 2px 2px 3px rgba($color: #000000, $alpha: 0.3);
}

.btn-green{
    background-color: transparentize($color: $green-color, $amount: 0.8);
    border: 2px solid lighten($color: $green-color, $amount: 20);
    color: white;

    &:hover {
        color: lighten($color: $green-color, $amount: 10);
        background-color: transparentize($color: $purple-color, $amount: 0.2);
        border: 2px solid transparentize($color: #000000, $amount: 1);
    }

    &:focus {
        color: white;
        border: 2px solid $purple-color;
    }
}

.btn-warning{
    background-color: transparentize($color: yellow, $amount: 0.5);
    border: 2px solid lighten($color: yellow, $amount: 15);
    color: white;

    &:hover {
        color: lighten($color: $green-color, $amount: 10);
        background-color: transparentize($color: $purple-color, $amount: 0.2);
        border: 2px solid transparentize($color: #000000, $amount: 1);
    }

    &:focus {
        color: white;
        border: 2px solid $purple-color;
    }
}