.container {
    width: 100%;
    max-width: 1280px;
    min-height: 100vh;
    margin: auto;
}

.layout-web{
    display: flex;
}

.layout-movil{
    display: none;
}

