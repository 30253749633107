.guide{
    background-color: rgb(254, 250, 255);
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 10% 0px 10%;
    h1{
        color: black;
        width: 100%;
    }
}