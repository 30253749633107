.form-container {
    width: 90%;
    min-height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.form {
    width: 100%;
    min-height: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.form-skills,
.form-fa {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-height: 30%;
    overflow: auto;
}

.form--skills-box {
    color: white;
    display: flex;
    margin-right: 20px;
    margin-top: 5px;
    width: 100%;
}

.form--skills-item {
    transition: all 0.3s ease;
}

.form--skills-delete {
    cursor: pointer;
    color: red;
    transition: all 0.3s ease;

    &:hover ~ .form--skills-item {
        text-decoration: line-through;
        color: darken($color: white, $amount: 20);
    }

    &:hover {
        color: darken($color: red, $amount: 15);
    }
}

.form-fa--box {
    color: white;
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-top: 10px;
    width: 100%;
}

.form-fa{
    max-height: 30%;
}
.form-fa--titulo{
    width: 70%;
}
.form-fa--fecha {
    width: 30%;
}

.form-fa--fecha{
    text-align: end;
}

.form-fa--desc {
    width: 100;
    margin-top: 5px;
    margin-left: 20px;
}

.form-i {
    color: white;
    font-size: 0.8rem;
}
