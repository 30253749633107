li {
    margin-bottom: 5px;
}

a {
    color: rgb(37, 37, 37);
}

hr {
    color: rgb(0, 255, 21);
}

.cv-container {
    width: 100%;
    height: 80vh;
    overflow: auto;
    margin: auto;
    padding: 10px;
    font-size: 8px;
    border-radius: 2px;
    box-shadow: 0px 3px 5px 5px rgba($color: #ffffff, $alpha: 0.2);
    background-color: white;

    h1{
        color: black;
    }
}

.seccion {
    margin-top: 20px;
    border-top: 1px solid rgb(58, 58, 58);
    padding-top: 15px;
}

.seccion-contacto{
    display: flex;
    flex-wrap: wrap;
}

.seccion-name {
    margin-bottom: 10px;
    color: rgb(19, 20, 24);
}

.seccion-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;
    color: rgb(45, 44, 53);
}

.seccion__p--cursiva{
    font-style: italic;
    width: 20%;
    text-align: end;
}

.tecnologias {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.tecnologias .seccion-name {
    width: 100%;
}

.habilidades {
    border-top: none;
    padding-top: 0px;
}

.hb-item{
    width: 100%;
    margin: 0px;
}

.personal-data {
    display: flex;
    flex-wrap: wrap;
}

.nombre {
    width: 100%;
    text-align: center;
}

.fa-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.fa-titulo{
    width: 80%;
    padding-left: 10px;
}

.fa-description{
    width: 100%;
    margin-left: 20px;
    text-align: justify;
}
.description {
    width: 100%;
    margin-left: 20px;
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
}

.cv-skill{
    width: 25%;
}
/* .description li{
    list-style-position: inside;
} */

.perfil {
    padding-left: 10px;
    text-align: justify;
}

.item{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}

.contacto-item{
    margin-left: 2px;
}