@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "Roboto";
//   src: url("../fonts/Roboto-Bold.ttf") format("truetype");
//   font-weight: bolder;
//   // font-style: normal;
// }

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
